import * as React from "react"
import {Router, Redirect} from "@reach/router"
// import Loadable from "@loadable/component"

import SignIn from "../pages/signin"

import SigninAccount from "../pages/signin-account"
import ForgotPassword from "../pages/forgot-password"
import ResetPassword from "../pages/reset-password"
import PasswordChanged from "../pages/password-changed"

// const LoadableSignInAccount = Loadable(() => import("../pages/signin-account"))
// const LoadableForgotPassword = Loadable(() =>
//   import("../pages/forgot-password"),
// )
// const LoadableResetPassword = Loadable(() => import("../pages/reset-password"))
// const LoadablePasswordChanged = Loadable(() =>
//   import("../pages/password-changed"),
// )

const SignInRoute = () => {
  return (
    <Router>
      <Redirect from="/" to="signin" noThrow />
      <SignIn path="signin" />
      <SigninAccount path="signin-account" />
      <ForgotPassword path="forgot-password" />
      <ResetPassword path="reset-password" />
      <PasswordChanged path="password-changed" />
    </Router>
  )
}

export default SignInRoute
